<template>
	<div class="reg-verification">
		<h1 class="reg-verification__title">
			{{ $t('web.verif_text') }}
		</h1>
		<img
			src="~@/assets/img/svg/broker/mail-verif.svg"
			alt="email verification"
			decoding="async"
		/>
		<p>{{ $t('web.check_email') }}</p>
	</div>
</template>

<script>
export default {
	mounted() {
		setTimeout(() => {
			this.$emit('prev')
		}, 3000)
	}
}
</script>

<style lang="scss">
.reg-verification {
	display: flex;
	flex-direction: column;
	max-width: 340px;
	gap: 40px;
	align-items: center;
	& > img {
		width: 100px;
		height: 100px;
	}
	& h1,
	p {
		margin: 0;
		padding: 0;
		color: var(--text-1, #000);
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
	}
}
</style>
